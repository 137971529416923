import React, { useEffect, useState } from 'react';

import Card from './../Card/Card';
import StartPage from '../StartPage/StartPage';
import EndPage from '../EndPage/EndPage';

import { shufleArr } from '../helpers/shufle';

import icon1 from '../../../assets/images/gameBlock/icon1.png';
import icon2 from '../../../assets/images/gameBlock/icon2.png';
import icon3 from '../../../assets/images/gameBlock/icon3.png';
import icon4 from '../../../assets/images/gameBlock/icon4.png';
import icon5 from '../../../assets/images/gameBlock/icon5.png';
import icon6 from '../../../assets/images/gameBlock/icon6.png';

import * as s from './GameBoard.module.css';

const cards = shufleArr([
  { id: 1, isOpen: false, value: icon3, isOnBoard: true, flag: 1 },
  { id: 2, isOpen: false, value: icon3, isOnBoard: true, flag: 1 },
  { id: 3, isOpen: false, value: icon4, isOnBoard: true, flag: 2 },
  { id: 4, isOpen: false, value: icon4, isOnBoard: true, flag: 2 },
  { id: 5, isOpen: false, value: icon1, isOnBoard: true, flag: 3 },
  { id: 6, isOpen: false, value: icon1, isOnBoard: true, flag: 3 },
  { id: 7, isOpen: false, value: icon6, isOnBoard: true, flag: 4 },
  { id: 8, isOpen: false, value: icon6, isOnBoard: true, flag: 4 },
  { id: 9, isOpen: false, value: icon2, isOnBoard: true, flag: 5 },
  { id: 10, isOpen: false, value: icon2, isOnBoard: true, flag: 5 },
  { id: 11, isOpen: false, value: icon5, isOnBoard: true, flag: 6 },
  { id: 12, isOpen: false, value: icon5, isOnBoard: true, flag: 6 },
])

const GameBoard = () => {
  const [isGameStart, setIsGameStart] = useState(false)
  const [isGameEnd, setIsGameEnd] = useState(false)
  const [counter, setCounter] = useState(0)
  const [openedCards, setOpenedCards] = useState([])
  const [cardsState, setCardsState] = useState(cards)

  useEffect(() => {
    if (counter === 12) {
      setTimeout(() => {
        setIsGameEnd(true)
      }, 1000)
    }
  }, [counter])

  const setOpenCard = (card) => {
    const index = cardsState.findIndex(el => el.id === card.id)
    setCardsState((cardsState) => {
      return [
        ...cardsState.slice(0, index),
        {
          ...card,
          isOpen: true,
        },
        ...cardsState.slice(index + 1),
      ]
    })
  }

  const setCardIsNotUnboard = (card) => {
    setCardsState((cardsState) => {
      return cardsState.map(el => {
        if (el.id === card.id || el.id === openedCards[0].id) {
          el.isOnBoard = false
        }
        return el
      })
    })
  }

  const setAllCardsIsNotOpen = () => {
    setCardsState((cardsState) => {
      return cardsState.map(el => {
        el.isOpen = false
        return el
      })
    })
  }

  const checkCards = (card) => {
    if (openedCards.length > 1) {
      return
    }

    if (openedCards.length === 0) {
      setOpenCard(card)
      setOpenedCards([card])
    }

    if (openedCards[0]?.id) {
      setOpenedCards(openedCards => [...openedCards, card])
      setOpenCard(card)
      if (card.flag === openedCards[0].flag && card.id !== openedCards[0].id) {
        setTimeout(() => {
          setCounter(counter => counter + 2)
        }, 300)
        setTimeout(() => {
          setCardIsNotUnboard(card)
          setOpenedCards([])
        }, 500)
      } else {
        setTimeout(() => {
          setAllCardsIsNotOpen()
          setOpenedCards([])
        }, 500)
      }
    }
  }

  return (
    <div className={s.gameBoard}>
      {isGameEnd
        ? <EndPage />
        : isGameStart
          ? <div>
            <div className={s.counter}><span>{counter}</span>/12</div>
            <div className={`${s.cards} ${!isGameStart && s.hide}`}>
              {cardsState?.map((card) => (
                <Card
                  key={card.id}
                  data={card}
                  checkCards={checkCards}
                />
              ))}
            </div>
          </div>
          : <StartPage
            isGameStart={isGameStart}
            setIsGameStart={setIsGameStart}
          />
      }
    </div>
  )
}

export default GameBoard
