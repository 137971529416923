import React from 'react'

import GameBoard from '../../components/gameComponents/GameBoard/GameBoard'
import Seo from '../../components/Seo'

import gameImage from '../../assets/images/jpg/seo/GameSEO.jpg'

const Index = () => {
  return (
    <>
      <Seo
        title="Justice | game"
        htmlAttributes={{
          lang: 'en',
        }}
        url="https://www.it-justice.com/"
        description="Contact us via e-mail, phone, social networks or visit our comfortable office in the centre of Taganrog."
        image={gameImage}
      />

      <div>
        <div>
          <GameBoard />
        </div>
      </div>
    </>
  )
}

export default Index
