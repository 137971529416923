import React from 'react';

import surprise from '../../../assets/images/gameBlock/Surprise.svg';
import * as s from './EndPage.module.css';

const EndPage = () => (
  <div className={s.endPage}>
    <img
      className={s.imageJigsaw}
      src={surprise}
      alt='Present image'
    />
    <h1 className={s.title}>Поздравляем!</h1>
    <span className={s.text}>
      Вы выиграли доступ к закрытому Telegram-каналу Justice IT.
      В нем Вы найдёте материалы для подготовки к стажировке, советы, как успешно пройти собеседование,
      а также полезные источники для изучения программирования и многое другое.
    </span>
    <a
      href={'https://t.me/joinchat/YZj_2RbvOSE0MzE6'}
      target="_blank"
      className={s.buttonStart}
      rel="noreferrer"
    >
      Задать вопрос
    </a>
  </div>
);

export default EndPage;
