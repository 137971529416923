import React from 'react';

import question from '../../../assets/images/gameBlock/_.svg';
import * as s from './Card.module.css';

const Card = ({ data, checkCards }) => {

  return (
    <div className={s.wrapper}>

      <button
        className={`${s.card} ${data.isOpen && s.active} ${
          !data.isOnBoard && s.hide
        }`}

        onClick={() => {
          checkCards(data)
        }}
      >
        <div className={s.front}>
          <img
            src={question}
            className={s.img}
            alt='Unknown image'
          />
        </div>
        <div className={s.back}>
          <img
            src={data.value}
            className={s.img}
            alt='Inverted image'
          />
        </div>
      </button>
    </div>
  );
};

export default Card;
