import React from 'react'

import jigsaw from '../../../assets/images/gameBlock/jigsaw.png'
import clownBg from '../../../assets/images/gameBlock/YasnoClown.png'
import * as s from './StartPage.module.css'

const StartPage = (props) => (
  <div className={s.startPage}>
    <img
      className={s.clownBg}
      src={clownBg}
      alt='clown background'
    />

    <div className={s.startInner}>
      <img
        className={s.imageJigsaw}
        src={jigsaw}
        alt='Jigsaw image'
      />

      <h1 className={s.title}>Хочешь в IT?</h1>

      <span className={s.text}>У тебя есть шанс показать себя и занять свое место по солнцем среди топовых специалистов нашего города.
      Сыграй с нами в игру и получи приз, который поможет прокачать твои скиллы!</span>

      <button
        className={s.buttonStart}
        onClick={() => props.setIsGameStart(true)}
      >
        Начать игру
      </button>
    </div>
  </div>
)

export default StartPage
